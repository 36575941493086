<template>
  <el-form
    ref="formRef"
    class="login-formdma"
    label-position="left"
    :model="form"
    :rules="rules"
  >
    <!-- <div ref="titleaaa" class="title-tips" @click="handlePassword">
      用户登录
    </div> -->
    <el-form-item prop="uUser">
      <el-input
        v-model.trim="form.uUser"
        v-focus
        placeholder="请输入账号名称"
        tabindex="1"
        type="text"
      >
        <template #prefix>
          <i class="iconfont icon-icon-gerenzhongxin"></i>
        </template>
      </el-input>
    </el-form-item>
    <el-form-item prop="dma">
      <el-input
        :key="passwordType"
        ref="passwordRef"
        v-model.trim="form.dma"
        placeholder="请输入账号密码"
        show-password
        tabindex="2"
        :type="passwordType"
        @keyup.enter="handleLogin"
      >
        <template #prefix>
          <i class="iconfont icon-icon2"></i>
        </template>
      </el-input>
    </el-form-item>

    <el-form-item v-if="form.utel" prop="utel">
      <el-input
        v-model.trim="form.utel"
        v-focus
        :disabled="true"
        placeholder="请输入手机号"
        tabindex="3"
        type="text"
      >
        <template #prefix>
          <i class="iconfont icon-icon-gerenzhongxin"></i>
        </template>
      </el-input>
    </el-form-item>
    <el-form-item v-if="form.utel" prop="sms" style="position: relative">
      <el-input
        :key="passwordType"
        ref="passwordRef"
        v-model.trim="form.sms"
        placeholder="请输入验证码"
        tabindex="4"
        @keyup.enter="handleLogin"
      >
        <template #prefix>
          <i class="iconfont icon-icon2"></i>
        </template>
      </el-input>
      <el-button
        class="phone-code"
        :disabled="yzmbtnloading"
        type="primary"
        @click="getWpassSmsfn"
      >
        {{ yzmbtnstr }}
      </el-button>
    </el-form-item>

    <div
      style="
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: -10px 0 -5px 0;
        color: #fff;
      "
    >
      <el-checkbox v-model="jzmm" label="记住密码" size="large" />
      <div style="cursor: pointer" @click="wjmm">忘记密码</div>
    </div>
    <el-form-item>
      <el-button
        class="login-btn"
        :loading="loading"
        :style="loginbtnbg"
        type="primary"
        @click="handleLogin"
      >
        登录
      </el-button>
    </el-form-item>
  </el-form>
</template>
<script>
  const validateUsername = (rule, value, callback) => {
    if ('' === value) callback(new Error('用户名不能为空'))
    else callback()
  }
  const validatePassword = (rule, value, callback) => {
    if ('' === value) callback(new Error('密码不能为空'))
    else callback()
  }
  // import { useUserStore } from '@/store/modules/user'
  import qs from 'qs'
  import request from '@/z/callback/request'
  // let userStore = useUserStore()
  export default {
    name: 'Dma',
    directives: {
      focus: {
        mounted(el) {
          el.querySelector('input').focus()
        },
      },
    },

    data() {
      return {
        loginbtnbg: {
          width: '180px',
          margin: '0 auto',
          background: 'none',
          backgroundImage: ` url('${DataURLimg}/index/submit.png')`,
          backgroundSize: '100% 100%',
        },
        loading: false,
        passwordType: 'password',
        form: {
          uUser: '',
          dma: '',
          utel: '',
          sms: '',
        },
        jzmm: true,
        rules: {
          uUser: [
            {
              required: true,
              trigger: 'blur',
              validator: validateUsername,
            },
          ],
          dma: [
            {
              required: true,
              trigger: 'blur',
              validator: validatePassword,
            },
          ],
        },
        yzmbtnloading: false,
        yzmbtnstr: '获取验证码',
      }
    },
    beforeMount() {
      let str = localStorage.getItem('account') || {}
      str = qs.parse(str)
      this.form = {
        ...this.form,
        ...str,
      }
    },
    methods: {
      async getWpassSmsfn() {
        if (this.yzmbtnloading) {
          return
        }
        this.yzmbtnloading = true
        console.log(this.form)
        let yzm = await request('/WpassSms', {
          utel: this.form.utel,
          area: 10,
        })
        this.yzmstr = yzm[0].par
        this.yzmbtnstrfn(60)
      },
      yzmbtnstrfn(time = 60) {
        time--
        this.yzmbtnstr = `${time}s后重新获取`
        if (time == 0) {
          this.yzmbtnstr = '重新获取'
          this.yzmbtnloading = false
        } else {
          setTimeout(() => {
            this.yzmbtnstrfn(time)
          }, 1000)
        }
      },
      wjmm() {
        window.location.href = './USearchdma.html'
        // this.$router.push('/register')
      },
      handlePassword() {
        this.passwordType === 'password'
          ? (this.passwordType = '')
          : (this.passwordType = 'password')
        // this.$refs['passwordRef'].focus()
      },
      dmatest(value) {
        if (value.length < 6) {
          return false
        }
        let str = `(?![0-9]+$)(?![a-zA-Z]+$)[a-zA-Z0-9]{6,}`

        let reg = RegExp(str, 'g')
        let r = reg.test(value)
        return r
      },
      async handleLogin() {
        this.$refs['formRef'].validate(async (valid) => {
          if (valid)
            try {
              this.loading = true
              let reqdata = this.form
              for (let i in reqdata) {
                if (reqdata[i] == '' || !reqdata[i]) {
                  delete reqdata[i]
                }
              }
              let GetSession = await request('/GetSession')
              if (GetSession[0].uUser == reqdata.uUser) {
                await this.$router.push('/index')
                return
              }

              let par = await this.$store.dispatch('user/login', reqdata)

              if (
                !this.dmatest(this.form.dma) &&
                par.length == 1 &&
                par[0].par == 'succeed'
              ) {
                this.$baseAlert(
                  '密码应为6位以上，且不能全为数字或字母,请修改密码后登录',
                  '温馨提示',
                  (type) => {
                    if (type == 'confirm') {
                      this.wjmm()
                    }
                  }
                )
                return
              }
              // await this.$store.login(this.form).catch(() => {})
              console.log(par)
              if (par.length == 0 && reqdata.sms) {
                this.$message({
                  message: '验证码错误',
                  type: 'warning',
                })
                return
              }
              if (this.jzmm) {
                localStorage.setItem(
                  'account',
                  qs.stringify({
                    uUser: this.form.uUser,
                    dma: this.form.dma,
                  })
                )
              }
              if (isNaN(Number(par[0].par))) {
                await this.$router.push('/index')
                // this.form.
              } else {
                this.form.utel = par[0].par
                this.getWpassSmsfn()
              }
              // jzmm
            } finally {
              this.loading = false
            }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .login-formdma {
    position: relative;
    .phone-code {
      position: absolute;
      top: 8px;
      right: 10px;
      width: 120px;
      height: 40px;
      font-size: 14px;
      color: #fff;
      cursor: pointer;
      user-select: none;
      border-radius: 3px;
    }
    .title {
      font-size: 54px;
      font-weight: 500;
      color: var(--el-color-white);
    }

    .title-tips {
      margin-bottom: 38px;
      font-size: 26px;
      font-weight: 400;
      color: var(--el-color-white);
      text-align: center;
    }

    .login-btn {
      display: inherit;
      width: 100%;
      height: 56px;
      margin-top: 5px;
      font-size: 20px;
      background-image: linear-gradient(#0296fb, #0b6ff9);
      border: 0;
      &:hover {
        opacity: 0.9;
      }
    }

    .tips {
      margin-bottom: 10px;
      font-size: 14px;
      color: var(--el-color-white);

      span {
        &:first-of-type {
          margin-right: 16px;
        }
      }
    }

    .title-container {
      position: relative;

      .title {
        margin: 0 auto 40px auto;
        font-size: 34px;
        font-weight: bold;
        color: var(--el-color-primary);
        text-align: center;
      }
    }

    i {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
      font-size: 20px;
      color: #fff;
    }

    .show-password {
      float: right;
      width: 32px;
      height: 32px;
      font-size: 16px;
    }
  }
</style>
